<template>
    <v-container>
      <v-row>
        <v-col>
          <h1>About Us</h1>
          <p>Learn more about our company and our mission.</p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  }
  </script>