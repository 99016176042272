<template>
    <v-container>
      <v-row>
        <v-col>
          <h1>Contact Us</h1>
          <v-form @submit.prevent="submitForm">
            <v-text-field 
              v-model="name" 
              label="Name" 
              required
            ></v-text-field>
            <v-text-field 
              v-model="email" 
              label="Email" 
              type="email" 
              required
            ></v-text-field>
            <v-textarea 
              v-model="message" 
              label="Message" 
              required
            ></v-textarea>
            <v-btn type="submit" color="primary">Send</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
    data: () => ({
      name: '',
      email: '',
      message: ''
    }),
    methods: {
      submitForm() {
        // Implement form submission logic
        alert('Form submitted!')
      }
    }
  }
  </script>