<template>
  <v-app class="app-background">
    <!-- <v-app-bar color="primary" dense>
      <v-menu location="left">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/" exact>
            <v-icon left>mdi-home</v-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item to="/about">
            <v-icon left>mdi-information</v-icon>
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contact">
            <v-icon left>mdi-email</v-icon>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title>My Vue App</v-toolbar-title>
    </v-app-bar> -->

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import '@/assets/style.css';

export default {
  name: 'App'
}
</script>