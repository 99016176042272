<template>
    <v-container>
      <v-row>
        <v-col>
            <link rel="stylesheet" type="text/css" href="style.css">
            <div class="wrapper">
            <img src="@/assets/FullLogoColor-cropped.svg" class="logo"/>
            <h1>Coming Soon!</h1>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'HomePage'
  }
  </script>